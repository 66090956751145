import React from "react"
import styled from "styled-components"

const HeroSection = styled.div`
  margin: 0;
  padding: 40px 0;
  .text-container {
    max-width: 500px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    h1 {
      opacity: 0;
      animation: HeroAnimation 3s 0.1s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
    }
    h5 {
      opacity: 0;
      animation: HeroAnimation 3s 0.2s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
    }
  }

  .button {
    opacity: 0;
    animation: HeroAnimation 3s 0.3s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
  }

  @keyframes HeroAnimation {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export default HeroSection
