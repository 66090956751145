import React from "react"
import styled from "styled-components"
import Card from "./Card"

const AppStoreReviews = () => (
  <CardsGrid>
    <Card
      title="Simple and exactly what I need"
      body="This is exactly what I needed to help boost productivity working with screenshot images!"
      name="Con826 (via the Mac App Store)"
    />
    <Card
      title="Fantastic"
      body="I’ve been using Shotty for several months, and it’s completely revamped how I manage screenshots."
      name="Shawn B (via the Mac App Store)"
    />
    <Card
      title="Nice and inobtrusive"
      body="Does what it says on the tin. It's one of those things that should come with macOS. Apple, acquire this guy please."
      name="GiacomoL (via the Mac App Store)"
    />
    <Card
      title="Best app for screenshot management"
      body="This is the best app for screenshot management, very user friendly and well-thought design! Highly recommend"
      name="Trung Pham (via the Mac App Store)"
    />
  </CardsGrid>
)

export default AppStoreReviews

const CardsGrid = styled.div`
  margin: 0 auto;
  display: grid;
  max-width: 930px;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  justify-items: center;
  @media (max-width: 970px) {
    grid-template-columns: 1fr;
  }
`
