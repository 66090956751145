import React from "react"
import styled from "styled-components"

const Card = props => (
  <Styles>
    <img src={require("../images/stars.svg")} alt="" />
    <h4>{props.title}</h4>
    <p>{props.body}</p>
    <p className="light">{props.name}</p>
  </Styles>
)

export default Card

const Styles = styled.div`
  width: 455px;
  padding: 40px;
  border-radius: 20px;
  background: ${props => props.theme.reviewCardBg};
  text-align: left;
  color: ${props => props.theme.fg};
  transition: ${props => props.theme.globalTransition};
  h4 {
    font-size: 22px;
  }
  p {
    font-size: 20px;
  }
  .light {
    opacity: 0.3;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`
