import React from "react"
import styled from "styled-components"

const Constraint = styled.div`
  max-width: ${props => props.maxWidth};
  margin: 0 auto;
  padding: ${props => props.padding};
  @media (max-width: ${props => props.maxWidth}) {
    max-width: 100%;
  }
`

export default Constraint
